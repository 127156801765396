
import Order from '@/modules/trade/Order'
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TheTradeTargetPrice',
  setup () {
    const order = inject('order') as Order
    const priceStep = computed(() => {
      return order.getProduct().value?.pointStep
    })

    return {
      order,
      priceStep,
    }
  },
})
