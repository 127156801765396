
import ChoiceButton from '@/components/ChoiceButton.vue'
import Icon from '@/components/Icon.vue'
import { speedProductByCode } from '@/modules/trade/trade'
import HoldOn from '@/provider/HoldOn.vue'
import Matrix from 'common/Matrix.vue'
import { computed, defineComponent, ref, watch, inject } from 'vue'
import { leversSchema, productLeverChange, readProduct } from '@/modules/trade/trade.api'
import * as R from 'ramda'
import { mul, div } from 'essential/tools/math'

export default defineComponent({
  name: 'TheTradeProducts',
  components: { ChoiceButton, HoldOn, Icon, Matrix },
  props: {
    code: {
      type: String,
      required: true
    },
    type: Number,
    paymethod: Number
  },
  setup(props) {
    const {
      minNumbers,
      levers,
      choiceQuantity,
      selectQuantity,
      choiceLeverage,
      selectLeverage,
      changeLevers,
      product
    } = speedProductByCode(props.code, props.type)
    const numValue = ref(1)
    const isDisabled = ref(true)
    const minQuantity = computed(() =>
      product.value?.specs ? Number(product.value.specs[0]?.minSl) : 0
    )
    const maxQuantity = computed(() =>
      product.value?.specs ? Number(product.value.specs[0]?.maxSl) : 1
    )

    const leverDefault = computed(() => {
      const temp = R.find((item: leversSchema) => item.isDefault === 1, levers.value)
      return temp ? Number(temp.lever) : 0
    })

    watch(
      () => choiceQuantity.value,
      () => {
        const tempNum = R.filter((item: string) => item === choiceQuantity.value, minNumbers.value)
        if (!tempNum.length) {
          isDisabled.value = false
          numValue.value = Number(choiceQuantity.value)
        }
      }
    )

    watch(() => props.paymethod, (val) => {
      const defaultLevers = R.find(R.propEq('choosed', 1), levers.value) ?? levers.value[0]
      const defaultLeversCredit = R.find(R.propEq('creditChoosed', 1), levers.value) ?? levers.value[0]
      const tempLever = val ? defaultLeversCredit : defaultLevers
      selectLeverage(tempLever)
      if (val) {
        if (Number(choiceQuantity.value) > 0.5 && isDisabled.value) {
          const filterNum = R.filter((item: string) => Number(item) <= 0.5, minNumbers.value)
          const sortNum = R.sort((a, b) => Number(b) - Number(a), filterNum)
          selectNumbers(sortNum[0])
        }
      }
    })

    const selectNumbers = (item: string) => {
      selectQuantity(item)
      isDisabled.value = true
      numValue.value = 1
    }
    const selectLever = (item: leversSchema) => {
      productLeverChange({ lever: item.lever, productCode: props.code, isJuan: props.paymethod }).then(() => {
        readProduct({ codes: props.code }).then((resp: any) => {
          const resLevers = resp?.levers
          if (resLevers) {
            changeLevers(resLevers)
            selectLeverage(R.find((i: any) => i.lever === item.lever, resLevers))
          }
        })
      })
    }

    const inputNumbers = () => {
      isDisabled.value = false
      selectQuantity(numValue.value.toString())
    }

    const color = inject('themeClass', '')

    // const showMore = () => openFullscreenList(TheTradeProductsMore, {
    //   code: props.code,
    //   choice,
    //   onSelect (option: Product) {
    //     select(option)
    //   },
    // })

    return {
      // products,
      // choice,
      // select,
      // showMore,
      minNumbers,
      levers,
      choiceQuantity,
      selectNumbers,
      choiceLeverage,
      selectLever,
      numValue,
      isDisabled,
      color,
      inputNumbers,
      validNumber(e: any) {
        const tempValue = Number(e.target.value)
        if (tempValue < minQuantity.value) {
          numValue.value = minQuantity.value
        }
        if (tempValue > maxQuantity.value) {
          numValue.value = maxQuantity.value
        }
        selectQuantity(numValue.value.toString())
      },
      product,
      minQuantity,
      maxQuantity,
      leverDefault,
      mul,
      div
    }
  }
})
