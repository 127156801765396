
import Order from '@/modules/trade/Order'
import { computed, defineComponent, inject } from 'vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { TradeDirection } from '@/types'

export default defineComponent({
  name: 'TheTradeCost',
  computed: {
    TradeDirection() {
      return TradeDirection
    }
  },
  components: { RealTimeQuote },
  props: {
    symbol: Object,
    type: String,
    costType: String,
    isCommission: Boolean,
  },
  setup () {
    const themeClass = inject<string>('themeClass')
    const order = inject('order') as Order

    return {
      themeClass,
      order,
      marginFormula: computed(() => order.getProduct().value?.marginFormula),
      code: computed(() => order.getProduct().value?.contract)
    }
  },
})
