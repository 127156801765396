
import Line from '@/components/Line.vue'
import TheStopLoss from '@/modules/trade/components/TheStopLoss.vue'
import TheTakeProfit from '@/modules/trade/components/TheTakeProfit.vue'
import Order from '@/modules/trade/Order'
import { computed, defineComponent, inject } from 'vue'
import { localGet } from 'essential/store/localStore'
import { keymap } from '@/config'

export default defineComponent({
  name: 'TheTradeLimits',
  components: {
    TheTakeProfit,
    Line,
    TheStopLoss,
  },
  props: {
    symbol: Object,
    isCommission: Boolean
  },
  setup () {
    const order = inject('order') as Order
    const noFee = computed(() => order.useCoupon.value)

    return {
      noFee,
      product: order.getProduct(),
      islamFlag: Boolean(Number(localGet(keymap.user.islamFlag))) || false,
    }
  },
})
